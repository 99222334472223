.pwd-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-pwd {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    background-color: rgba(55, 87, 132, 0.89) !important;
}
.bienvenida {
    color: white;
    font-weight: bold;
}
.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.background-pwd {
    background: url('../../../../assets/img/background-login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
}
.card-pwd .form-control {
    background-color: transparent !important;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid #ffff;
    color: #3cb7ff;
}

.card-pwd .form-control:focus {
    background-color: transparent;
    color: #3cb7ff;
}

.card-pwd .form-control::placeholder {
    color: #ffff;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffff;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffff;
}

.buttons-box button {
    background-color: #ef5543 !important;
    border: unset;
    border-radius: 2rem;
}

.card-pwd h1 {
    font-size: 1.7rem;
    font-weight: bold;
}

.card-pwd img {
    width: 10rem;
}

.card-pwd .new-account button {
    background-color: #4289ec;
    border: unset;
}

.card-pwd .password-recovery a {
    color: #ffff;
    font-style: italic;
}

.card-pwd span {
    color: #ffff;
    word-break: normal;
    font-size: 1rem;
}
