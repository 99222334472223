.card-container{
    box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
    min-width: 280px;
    max-height: 100px;
}

.card-container h2{
    margin-bottom: -15 !important; 
    font-size: 1rem !important;
}

.card-item{
    min-width: 280px;
    max-height: 50px;
    border-bottom: 1px solid #E5E5E5;
    padding: 10px 15px;
}