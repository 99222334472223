.minute textarea.form-control {
    max-width: 100%;
}

.minute div.upload-container {
    max-width: 100%;
}

.icon-exito {
    color: #ef5543 !important;
    display: inline-block !important;
}

.btn-old-files {
    background-color: #e0e0e0 !important;
    color:hsl(0, 0%, 28%) ;
    font-weight: normal;
    font-family: 'Montserrat';
    border: 1px solid transparent;
    padding: 0.5625rem 1rem;
    font-size: 0.9rem;
    line-height: 1.125;
    border-radius: 0.25rem;
    -webkit-transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.main-container-g {
    display: flex !important;
    flex-direction: row !important;
}

.multi-file-box-a {
    width: 15rem;
    text-align: justify !important;
}
.multi-file-box-b {
    text-align: justify !important;
}

.DisabledBeneficiaryModal {
    /*max-width: 440px !important;
    max-height: 230px !important;
    width: 100%; */
}


.multi-file-box-a img {
    width: 1.3rem;
}
.btn-delete {
    cursor: pointer;
}

.tr-not-active {
    text-decoration:  line-through;
    color: #7A7C7F;

}

.tr-not-active .react-select-container {
    opacity: 0.6;
}

.tr-alert-assistance {
    color: #ff6700;
}

.continerSpan {
    font-weight: bold;
    cursor: pointer;
}