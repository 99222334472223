.title-underline {
    --ww: 0;
    --color: #ef5543;
    color: var(--color) !important;
    font-size: 1.375rem;
    font-family: 'Montserrat';
    font-weight: 700 !important;
    max-width: max-content;
}

.title-underline::after {
    display: block;
    content: '';
    border-bottom: 3px solid var(--color);
    width: var(--ww);
    position: relative;
    right: 1.5rem;
    top: -7px;
    margin: 0;
}

.title-underline.blue {
    --ww: 0;
    color: #375784 !important;
    font-size: 1.375rem;
    font-family: 'Montserrat';
    font-weight: 700 !important;
    max-width: max-content;
}
