.header-modal h1 {
    color: black;
    font-size: 1rem;
}

.register-custom-modal {
    border-radius: 1rem;
}
.header-title-modal {
    color: black !important;
    font-size: 2rem !important;
    font-weight: bold;
}
.policy-text p {
    font-size: 0.7rem;
    margin-bottom: 1rem;
    word-break: break-all;
}

.register-custom-modal label {
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
}

.background-register {
    background: url('../../../../../assets/img/background-login.png');
    background-repeat: no-repeat;
    background-size: contain 100%;
    background-position: center;
    height: 100vh;
}

.container-register {
    border-radius: 0.5rem;
    background-color: #ffffff;
    max-height: 80vh;
    overflow-y: scroll;
    height: 100%;
}

.logo-iwoka {
    height: 4rem;
}
