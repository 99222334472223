.notification-error {
    background-color: #ffff !important;
    border: 1px solid #c24943;
    color: #c24943 !important;
}
.notification-info {
    background-color: #ffff !important;
    border: 1px solid #44a0bb;
    color: #44a0bb !important;
}

.notification-warning {
    background-color: #ffff !important;
    border: 1px solid #f89e1f;
    color: #f89e1f !important;
}
.notification-success {
    background-color: #ffff !important;
    border: 1px solid #62ac62;
    color: #62ac62 !important;
}

.notification-error .title {
    color: #c24943 !important;
}

.notification-info .title {
    color: #44a0bb !important;
}
.notification-warning .title {
    color: #f89e1f !important;
}
.notification-success .title {
    color: #62ac62 !important;
}
.notification {
    border-radius: 0.75rem !important;
    opacity: 1 !important;
    box-shadow: none !important;
}
