.upload-container-ads {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 15rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
 
}

.upload-text-ads {
  text-align: center;
  font-size: 0.85rem;
  font-weight: normal;

}

.uploaded-file-image-ads {
  max-width: 100%;
}
.uploaded-file-text-ads {
  font-size: 15px;
  font-weight: bold;
}
.uploaded-image-container-ads {
  background-color: transparent !important;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: 'Montserrat';
  width: 100%;
  height: 100%;
  left: -5px;
  bottom: 15px;
}



.is-button-remove-ads{
  width: initial;
  margin: 0px;
  border: 0px;
  cursor: pointer;
  display: inline-block;
  vertical-align: bottom;
  background: #ef5543 !important; 
  color:#ffff !important;
  border-radius: 4px !important;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  min-width: 32px;
  min-height: 32px;
  height: 32px;
  padding: 0px 16px 4px;
  line-height: 32px;
  position: relative;
  margin-bottom: 0.40rem;

}