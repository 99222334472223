.rc-assistance .rc-tabs-nav-wrap {
  width: 100% !important;
}

.rc-assistance .rc-tabs-nav-wrap div {
  width: 100% !important;
}

.rc-assistance .rc-tabs-nav-wrap div div {
  width: 100% !important;
}

.rc-assistance .rc-tabs-ink-bar {
  all: unset;
}

.rc-assistance .rc-tabs-nav .rc-tabs-tab {
  color: #375784 !important;
  max-width: 50% !important;
}

.rc-assistance .rc-tabs-tab-active {
  border-bottom: 3px solid !important;
  border-bottom-color: #375784 !important;
  color: #375784;
  font-weight: bold;
}
.rc-assistance .rc-tabs-bar div {
  display: flex;
}
.rc-assistance .rc-tabs-tab {
  flex: 1;
  color: #e5e5e5;
  background-color: #ffff;
  border-bottom: 2px solid #e5e5e5;
  justify-content: center;
}
.rc-assistance .rc-tabs-top .rc-tabs-tab {
  padding: 0.5rem 0rem 0.5rem 0rem;
}
.rc-assistance .rc-tabs-top .rc-tabs-bar {
  border-bottom: unset;
}

@media screen and (max-width: 576px) {
  .rc-assistance .rc-tabs-bar div {
      flex-direction: column;
  }
}
.rc-assistance .rc-tabs-top .rc-tabs-tab {
  color: #787676;
}