/* Checkbox and radio button */
/* Los colores con red aun no se saben de que son */
.c-checkbox,
.c-radio {
    margin-right: 4px;
}
.c-checkbox *,
.c-radio * {
    cursor: pointer;
}
.c-checkbox input,
.c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: sub;
    width: 20px;
    height: 20px;
    border: 2px solid #ef5543;
    text-align: center;
    border-radius: 5px;
}
.c-checkbox span:before,
.c-radio span:before {
    margin-left: 1px;
}

.c-radio:hover span {
    border: 2px solid #ef5543;
}
.c-checkbox:hover span {
    border: 2px solid #ef5543;
}
.form-inline .c-checkbox span,
.form-inline .c-radio span {
    margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
    border-radius: 500px;
}

.c-checkbox input[type='checkbox']:checked + span,
.c-checkbox input[type='radio']:checked + span,
.c-radio input[type='checkbox']:checked + span,
.c-radio input[type='radio']:checked + span {
    border-color: #ef5543;
    background-color: #ef5543;
}
