.title-text {
    font-size: 1.5rem;
    font: normal normal bold 34px/42px;
    font-weight: bolder;
    color: #375784;
}

.btn-create {
    top: 235px !important;
    left: 1491px !important;
    width: 340px !important;
    height: 46px !important;
    background: #ef5543 0% 0% no-repeat padding-box !important;
    border-radius: 29px !important;
    color: #ffffff !important;
}

.filter-select{
    min-width: 15rem !important;
    width: 100%;
}

@media screen and (min-width: 576px) {
    .filter-select {
        width: unset;
    }
}