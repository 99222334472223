.table-title-group {
    color: #ef5543 !important;
    font-size: 1.375rem;
    font-family: 'Montserrat';
    font-weight: 700 !important;
}

.table-title-group::after {
    display: block;
    content: '';
    border-bottom: 3px solid #ef5543;
    width: 15rem;
    position: relative;
    right: 1.5rem;
    bottom: 0px;
    margin: 0;
}
