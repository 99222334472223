.rbc-day-slot .rbc-event {
    background-color: #375784;
    border-radius: unset;
    border: 0.25rem solid #ffffff !important;
}

.rbc-month-row {
    border: unset;
    box-shadow: 0px 25px 30px #0000000d;
}

.rbc-month-view {
    border: unset;
    box-shadow: 0px 25px 30px #0000000d;
}

.rbc-row.rbc-month-header {
    color: #375784;
}
.rbc-time-view {
    border: unset;
}
.rbc-row.rbc-time-header-cell {
    color: #375784;
}

.rbc-label {
    color: #707070;
}
.rbc-day-bg .rbc-today {
}

@media (max-width: 991.99px) {
    .rbc-btn-group {
        width: 100% !important;
        text-align: center !important;
    }
}

.InfoEvent {
    /*background-color: #40e8b7 !important;*/
    position: relative;
}

.InfoEventModal {
    max-width: 550px !important;
    /*max-height: 230px !important;*/
    width: 100%;
}

.InfoEventModal-root {
    z-index: 1000 !important;
}

.checkEventHead::after {
    content: "✔";
    background-color: rgb(152, 194, 67);
    font-size: 0.7rem;
    padding: 0.2rem;
    position: absolute;
    right: 3px;
    top: 7px;
}

.chSave::before {
    content: "S";
    background-color: rgb(17, 0, 252);
    padding: 0.2rem;
    position: absolute;
    right: 50%;
    top: 3px;
}
.chIsUltime::before {
    content: "U";
    background-color: rgb(234, 0, 255);
    padding: 0.2rem;
    position: absolute;
    right: 55%;
    top: 3px;
}

.checkEventHead:hover::before {
    content: "Evento diverso";
    background-color: #555;
    padding: 0.2rem;
    position: absolute;
    right: 23px;
    top: 3px;
}

.square {
    height: 15px;
    width: 15px;
    max-width: 15px !important;
    min-width: 15px !important;
    background-color: #555;
    border-radius: 3px !important;
    display: inline-block !important;
}

.square.group {
    background-color: #98589f !important;
}

.square.individual {
    background-color: #e13255 !important;
}

.square.personal {
    background-color: #71cde3 !important;
}

.square.sena {
    background-color: #ffcc29 !important;
}

.text-info-modal {
    display: inline-block !important;
}

.buttons-info-modal {
    width: 100% !important;
    background-color: #0056b3 !important;
}

/*.InfoEventModal button.react-responsive-modal-closeButton {
    margin-bottom: 5px !important;
}*/


/* .calendar-container{
    max-width: 90vw;
    overflow-y: scroll;
} */
.rbc-allday-cell{
    display: none;
}

@media screen and (max-width: 767px) {
    .InfoEventModal  {
        max-width: 95vw !important;
        margin: auto;
        margin-top: 1rem;
    }
}

