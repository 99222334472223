.group-mentoring-event .event-box {
    background-color: #98589f !important;
}
.individual-mentoring-event .event-box {
    background-color: #e13255 !important;
}
.personal-event .event-box {
    background-color: #71cde3 !important;
}
.sena-event .event-box {
    background-color: #ffcc29 !important;
}

.event-box {
    width: 0.75rem;
    height: 0.9rem;
    min-width: 0.75rem;
}
.event-title {
    white-space: normal;
}
