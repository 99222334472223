.nav-link img {
    width: 1rem;
}
.nav-link:hover img {
    transform: scale(1.1);
}

.item-icon-wrapper {
    width: 1.2rem;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    background-color: transparent;
    border: unset !important;
}

.ant-menu-sub.ant-menu-inline {
    background-color: transparent !important;
}
.ant-menu-submenu-title {
    color: #ffffff !important;
    font-weight: 200;
    font-family: 'Montserrat';
    font-size: 0.82rem;
    border: unset !important;
    margin: 0px !important;
}
.ant-menu-submenu-title:hover {
    font-weight: bold;
}
.ant-menu-submenu-title .anticon + span {
    margin: 0px !important;
    padding-left: 0.25rem !important;
}

.ant-menu-submenu-title .anticon {
    width: 1.2rem !important;
}
.ant-menu-submenu-title .anticon svg {
    font-size: 16px;
}
.ant-menu-submenu-title:hover .anticon svg {
    font-size: 16px;
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
    background-color: #ffffff !important;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background-color: #ef5543 !important;
}

.ant-menu-vertical-left {
    border: unset !important;
}

.nav-item .nav-link {
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat';
    will-change: background-color, box-shadow, color;
    -webkit-transition: box-shadow 0.2s ease, color 0.2s ease,
        background-color 0.2s ease;
    transition: box-shadow 0.2s ease, color 0.2s ease,
        background-color 0.2s ease;
    font-size: 0.82rem;
    border-radius: 1.75rem;
    text-overflow: ellipsis;
    align-items: center;
    border-bottom: 1px solid #e1e5eb;
    font-weight: 200;
    color: #ffffff;
    padding: 0.75rem 1.5625rem;
    border-radius: 1.75rem;   
}

.nav-item .onlyMenu{
    display: flex;
}


.nav-item .nav-link span {
    white-space: nowrap;
}

.menu-item span{
    text-overflow: ellipsis;
    overflow: hidden; 
    margin-left: 20px;
    white-space: nowrap;
}

.nav-item .nav-link i {
    min-width: 1.25rem;
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    will-change: color;
    color: #cacedb;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
    margin-right: 0.375rem;
}

.nav-item .nav-link i.material-icons {
    font-size: 1.125rem;
    top: -1px;
}

.nav-item.active,
.nav-item .nav-link.active,
.nav-item .nav-link:hover,
.nav-item:hover {
    background-color: #375784;
    color: #ffffff;
    font-weight: bold;
    border-radius: 1.75rem;
}

.nav-item.active i,
.nav-item .nav-link.active i,
.nav-item .nav-link:hover i,
.nav-item:hover i {
    color: #ffffff;
    font-weight: bold;
    border-radius: 1.75rem;
}

.nav-item .nav-link {
    border-bottom: 0;
}

.main-sidebar .toggle-sidebar {
    border-left: none !important;
}

.iwoka-logo {
    position: absolute;
    right: -50px;
    height: 75vh;
    bottom: 0;
    z-index: -1;
}

aside::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 768px) {
    aside {
        max-width: 24.5vw !important;
    }
}

@media screen and (min-width: 992px) {
    aside {
        max-width: 16.5vw !important;
    }
}
