.width-switch{
    width: 40%;
}

@media screen and (max-width: 768px) {
    .width-switch{
        width: 50%;
    }
}
@media screen and (max-width: 480px) {
    .width-switch{
        width: 100%;
    }
}

.title-color{
    color: #ef5543 !important;
}
