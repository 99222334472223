.dnd-row {
    color: #2f2f2f;
    font-weight: bold;
    -webkit-box-shadow: 0px 2px 0px 0px #bebebe;
    box-shadow: 0px 2px 0px 0px #bebebe;
    font-weight: normal;
}
.dnd-row.select {
    background-color: #37578445;
    color: #fff;
}

#draggable-portal {
    position: absolute;
    pointer-events: none;
    width: 100%;
}

.dnd-table {
    width: 100% !important;
}

.dnd-table .header {
    color: #2f2f2f;
    font-weight: bolder;
    border-bottom: 2px solid #bebebe;
}
.dnd-row-content {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.dnd-row .icon {
    width: 2rem;
}

.dnd-table .header {
    display: flex;
    flex-direction: row;
}

.dnd-header-content {
    flex: 1;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.dnd-row {
    display: flex;
    flex-direction: row;
}

.dnd-row div {
    flex: 1;
}
