.radio-main{
    display: flex;
    flex-direction: column;
    align-items:center;
    min-width: 120px;
    color: #375784;
}

.radio-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-right: 12px;
    cursor: pointer;
    line-height: 24px;
}
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.radio-container input:checked ~ .checkmark {
    border: 1px solid #375784;
    background: #375784;
    box-shadow: 0 5px 5px rgba(90, 97, 105, .5);
}
.radio-container input:checked ~ .checkmark:after {
    display: block;
}
.radio-container input[disabled] ~ .checkmark {
    background: #c8c8c8;
    border: none;
    cursor: not-allowed;
}
.radio-container .checkmark {
    position: absolute;
    top: 0;
    left: 8px;
    height: 22px;
    width: 22px;
    box-shadow: 0 5px 5px rgba(90, 97, 105, .5);
    border-radius: 50%;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
}
.radio-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6.5px;
    top: 3px;
    width: 6.2px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}