.rc-tabs-tab {
    background-color: #f2f2f2;
    font-size: 1rem;
    font-family: 'Montserrat';
    letter-spacing: 0.44px;
}
.rc-tabs-nav-wrap {
    background-color: #f2f2f2;
}
.rc-tabs-tab-active,
.rc-tabs-tab-active {
    color: #007bff !important;
    background-color: white;
    border-bottom: 2px solid;
}
.rc-tabs-ink-bar {
    background-color: #007bff;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0px;
    padding: 10px 50px 10px 50px;
    color: #000000;
}
.rc-tabs-tab:hover {
    color: green;
}
.rc-tabs-top {
    border-bottom: none;
}

.rc-tabs-bottom .rc-tabs-tab {
    padding-left: 30px;
    padding-right: 30px;
}

.rc-group .rc-tabs-nav-wrap {
    width: 100% !important;
}

.rc-group .rc-tabs-nav-wrap div {
    width: 100% !important;
}

.rc-group .rc-tabs-nav-wrap div div {
    width: 100% !important;
}

.rc-group .rc-tabs-ink-bar {
    all: unset;
}

.rc-group .rc-tabs-nav .rc-tabs-tab {
    color: #375784 !important;
    max-width: 50% !important;
}

.rc-group .rc-tabs-tab-active {
    border-bottom: 3px solid !important;
    border-bottom-color: #375784 !important;
    color: #375784;
    font-weight: bold;
}
.rc-group .rc-tabs-bar div {
    display: flex;
}
.rc-group .rc-tabs-tab {
    flex: 1;
    color: #787676;
    background-color: #ffff;
    border-bottom: 2px solid #e5e5e5;
    justify-content: center;
}
.rc-group .rc-tabs-top .rc-tabs-tab {
    padding: 0.5rem 0rem 0.5rem 0rem;
}
.rc-group .rc-tabs-top .rc-tabs-bar {
    border-bottom: unset;
}

@media screen and (max-width: 576px) {
    .rc-group .rc-tabs-bar div {
        flex-direction: column;
    }
}

.rc-group .rc-tabs-content .rc-tabs-tabpane {
    overflow: hidden !important;
}