.total-table{
    width: 100%;
}

.total-table thead {
    color: #EF5543 !important ;
    background-color: transparent;
    background-color: initial;
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    font-size: 1rem !important;
    border-bottom:3px solid #C4C4C4 !important;
    font-weight: bolder;
}

.total-table td, .total-table th {
    padding: 0.15rem 0.75rem;
    padding: 0.75rem;
    vertical-align: middle;
}
.total-table td {
    border-bottom:1px solid #C4C4C4 !important;
}

.total-table tbody {
    font-size: 0.85rem !important;
    color: #5F5F5F !important;
}

.total-table tbody .first {
    color: #375784 !important;

}


.table-second {
    width: 100%;
}


.table-second thead {
    color: #375784 !important ;
    background-color: transparent;
    background-color: initial;
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    font-size: 1rem !important;
    border-bottom:3px solid #C4C4C4 !important;;
    font-weight: bolder;
}

.table-second td, .table-second th {
    padding: 0.15rem 0.75rem;
    padding: 0.75rem;
    vertical-align: middle;
}

.table-second td {
    border-bottom:1px solid #C4C4C4 !important;;

}
.table-second tbody {
    font-size: 0.85rem !important;
    color: #5F5F5F !important;
    border-bottom:1px solid #C4C4C4 !important;;
}

.table-second td.first {
    color: #375784 !important;
}

.historic-text {
    font-size: 1rem !important;
    color: #5F5F5F !important;
}

.img-youth{
    position: absolute;
    right: -96px;
    bottom: -45px;
    opacity: 0.5;
    width: 230px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
.is-relative{
    position: relative!important;
    overflow: hidden !important;
}

.img-kids{
    position: absolute;
    right: 0px;
    bottom: -5px;
    opacity: 0.35;
    width: 125px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.img-hands{
    position: absolute;
    right: 0px;
    bottom: 0px;
    opacity: 0.35;
    width: 140px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}