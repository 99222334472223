.DialogBeforeUnLoad {
    .MuiSvgIcon-root {
        font-size: 7rem;
        font-weight: 200;
        color: #ef5443a9 !important;
        margin: 0 auto;
        margin-top: 20px;
    }
    .MuiDialogTitle-root {
        text-align: center;
        font-size: 1.8rem;
        font-weight: bold;
        color: #444444 !important;
    }

    .MuiDialogContent-root {
        text-align: center;
        margin-bottom: 0;

        .MuiTypography-root {
            margin-bottom: 16px;
            font-size: 1.4rem;
            color: #444444 !important;
        }
        .MuiTypography-root:last-child {
            margin-bottom: 0;
        }
        .MuiTypography-root:first-child:before {
            content: '¡';
            height: 1px;
            width: 100%;
            margin-bottom: 16px;
        }
        .MuiTypography-root:last-child::after {
            content: '!';
            height: 1px;
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .MuiDialogActions-root {
        justify-content: center;
        margin-bottom: 8px;

        .MuiButton-outlined {
            margin-right: 16px;
            font-size: 1.2rem;
            box-shadow: none;
            background-color: #375784 !important;
            color: #fff !important;
            filter: opacity(0.7);
            max-height: 2.5rem !important;
            padding: 0.75rem 1rem !important;
            border-radius: 1rem !important;
            text-transform: none !important;
            &:hover {
                background-color: #375784 !important;
                color: #fff !important;
                filter: opacity(1);
            }
        }
        .MuiButton-contained {
            border-radius: 4px;
            font-size: 1.2rem;
            box-shadow: none;
            background-color: #ef5543 !important;
            color: #fff !important;
            filter: opacity(0.7);
            max-height: 2.5rem !important;
            padding: 0.75rem 1rem !important;
            border-radius: 1rem !important;
            text-transform: none !important;
            &:hover {
                background-color: #ef5543 !important;
                color: #fff !important;
                filter: opacity(1);
            }
        }
    }
    .MuiDialog-paper {
        border-radius: 0.6rem !important;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        animation: popAnimation 0.3s ease-in-out;
    }
}

@keyframes popAnimation {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}
