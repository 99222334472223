.student-modal{
    min-height: 457px;
}



@media screen and (max-width: 876px) {
    .student-modal  {
        max-width: 95vw !important;
        margin: auto;
        margin-top: 1rem;
    }
}

.table.table-bordered.table-body td {
    border: unset;
    border-top: 2px solid #3757842b;
    padding: 0.35rem 0rem 0.35rem 0rem;
}
