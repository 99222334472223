.react-bs-table-bordered.table-container-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 30px #0000000d;
    border: unset;
}

.table.table-bordered.table-header tr th {
    border: unset;
    padding: 0.7rem 0rem 0.7rem 0rem;
}

.table.table-bordered.table-body td {
    border: unset;
    border-top: 2px solid #3757842b;
    padding: 0.7rem 0rem 0.7rem 0rem;
}
.page-item.active .page-link {
    color: #fff !important;
    background-color: #ef5543 !important;
    border-color: #ef5543 !important;
}
.page-link {
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #ef5543 !important;
    background-color: #fff;
    border: none;
    margin: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.react-bootstrap-table-page-btns-ul.pagination {
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    background-color: transparent !important;
}

th:first-child,
td:first-child {
    left: 0;
    width: 130px;
}
