.title-icon{
    position: relative;
}

.title-icon::after {
    display: block;
    content: '';
    border-bottom: 7px solid #375784 ;
    width: 250px;
    position: absolute;
    left: 0;
    top: 38px;
    margin-right: 30px;
    /* margin: 0; */
}