.sync-text{
  text-align: center;
  font-weight: bolder;
  color: rgb(225, 50, 85);
}

.btn-sync{
  display: flex;
  align-items: center;
  background-color: #F1F1F1;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
  outline: unset;
  border: 0px;
  max-height:2.5rem
}