.tab-container{
    display: flex;
    justify-content: space-between;
    background-color: transparent;
}

.tab-container button{
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
    text-align: left;
}


.tab-item{
    width: 100%;
    color: #E5E5E5;
    font-size: 1.3rem;
    padding: 6px 0;
    border-bottom: 4px solid #E5E5E5;
}
.tab-item.active{
    color: #375784;
    border-bottom: 4px solid #375784;
}

.tab-item:hover{
    color: #375784;
    text-decoration: none;
}

.tab-item.active label,
.tab-item:hover label{
    color: #EF5543;
    font-weight: bold;
}