.card-default {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 30px #00000019;
    border-radius: 0.625rem;
    padding: 1rem;
}

.card-default .title {
    text-align: left;
    font-size: 1.375rem;
    font-weight: bold;
    letter-spacing: 0.44px;
    color: #375784;
}
.card-primary {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 30px #00000019;
    border-radius: 0.625rem;
    border-left: 2rem solid;
    border-left-color: #fbd0cb;
    padding: 1rem;
    color: #000000;
}

.card-primary .title {
    text-align: left;
    font-size: 1.375rem;
    font-weight: bold;
    letter-spacing: 0.44px;
    color: #375784;
}

.card-secondary .title {
    text-align: left;
    font-size: 1.375rem;
    font-weight: bold;
    letter-spacing: 0.44px;
    color: #375784;
}
.card-secondary {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 30px #00000019;
    border-radius: 10px;
    border-left: 2rem solid;
    border-left-color: #c9d1de;
    padding: 1rem;
    color: #000000;
}
