.login-overlay .panel-body {
    min-height: 244px;
}
.has-feedback {
    position: relative;
}
.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-login {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    background-color: rgba(55, 87, 132, 0.89) !important;
}
.bienvenida {
    color: white;
    font-weight: bold;
}
.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue-gradient-bg {
    height: 100vh;
    background: #007bff;
    background: linear-gradient(to right, #0062e6, #3ac1ff);
}
.background-login {
    background: url('../../../../../assets/img/background-login.png');
    background-repeat: no-repeat;
    background-size: contain 100%;
    background-position: center;
    height: 100vh;
}
.card-login .form-control {
    background-color: transparent !important;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid #ffff;
    color: #3cb7ff;
}

.card-login .form-control:focus {
    background-color: transparent;
    color: #3cb7ff;
}

.card-login .form-control::placeholder {
    color: #ffff;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffff;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffff;
}

.card-login h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #ffff;
}

.card-login img {
    width: 10rem;
}

.card-login .new-account button {
    background-color: #4289ec;
    border: unset;
}

.card-login .password-recovery a {
    color: #ffff;
    font-style: italic;
}

.register-link,
.register-link:hover {
    color: #ffffff;
    text-decoration: underline !important;
    -webkit-text-underline-position: under;
    -ms-text-underline-position: under;
    text-underline-position: under;
    text-decoration-color: #ffffff;
}
